import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Headline74, Headline50 } from '../FontStyle';
import FooterContact from '../Footer/FooterContact';
import CardsSelected from './CardsSelected'

import bgImage from '../../img/SelectedCandidates/bgImage.jpg'
import useFetch from '../Hooks/useFetch';
import ErrorPage from '../ErrorPage';
import SetLanguages from '../SetLanguages';

import { API } from '../../Consts/Api';
import { Context } from '../../Context/context';

const SelectedCandidatesWrapper = styled.div`
  width:100%;
  height:100%;
  position:absolute;
  background-image:url(${bgImage});
  background-position:top center;
  background-size: cover;
  background-repeat: no-repeat;
`
const Wrapper = styled.div`
  width:80%;
  margin:150px auto 60px auto; 
  @media (max-width:768px){
    width:90%;
    margin:30px auto 60px auto; 
  }
`
const Section = styled.div`
   display:flex;
   justify-content:space-between;
   align-items:center;
   .heading{
    width:65%;
    margin:0px;
   }
   .number{
     font-size:74px;
     color:#FBD132;
     font-weight:bold;
     margin-right:13px;
    }
   .text{
     font-size:28px;
     font-weight:bold;
     color:#3A3A3A;
   }
   div{
        width:35%;
        display:flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width:768px){
            width:100%;
            justify-content:center;
        }
    }
    @media (max-width:768px){
      .heading{
        display:none;
      }
      .number{
        font-size:59px;
       }
       .text{
         font-size:18px;
       }
      div{
        width:100%;
      }
  }
`
const LanguagesWrapper = styled.div`
  width:80%;
  margin:0 auto;
  padding-top:70px;
  display:flex;
  justify-content:flex-end;
  @media (max-width:768px){
    padding-top:180px;
  }
`

const FilterButtonWrapper = styled.div`
  margin: 0 -20px;
  padding-bottom: 50px;
  padding-top: 50px;
`;

const FilterButton = styled.div`
  border: 2px solid #FBD132;
  border-radius: 10px;
  padding: 20px;
  display: inline-block;
  margin: 10px 10px;

  &.active {
    background: #FBD132;
  }

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
const SelectedCandidates = ({ href }) => {
  const [activeTalentMarket, setActiveTalentMarket] = useState(false);
  const company = useFetch(API.routes.company.replace('{name}', href.replace('/', '')));

  const changeActiveTalentMarket = function (id) {
    company.data.talent_markets.map(market => {
      market.id == id && setActiveTalentMarket(market)
    })
  }

  useEffect(() => {
    company && company.data.talent_markets && setActiveTalentMarket(company.data.talent_markets[0])
  }, [company]);

  const { selectedLang } = useContext(Context)
  return (
    <>
      {/* {company && company.data.talent_markets.length > 0 && */}
      {activeTalentMarket &&
        <SelectedCandidatesWrapper>
          <LanguagesWrapper>
            <SetLanguages />
          </LanguagesWrapper>
          <Wrapper>
            {/* <Section>
              <Headline74 className="heading">{Data.talentMarkets.title}</Headline74>
              <div>
                <span className="number">{company && company.data.talent_markets && company.data.talent_markets.length}</span>
                <span className="text">{Data.talentMarkets.talentMarketsNumber}</span>
              </div>

            </Section> */}
            <FilterButtonWrapper>
              {company.data.talent_markets.map((market, i) => (
                <FilterButton onClick={() => { changeActiveTalentMarket(market.id) }} className={market.id == activeTalentMarket.id ? 'active' : ''}>{market.name}</FilterButton>
              ))}
            </FilterButtonWrapper>
            <Section>
              <Headline74 className="heading">{Data.talentMarkets.subtitle}</Headline74>
              <div>
                <span className="number">{activeTalentMarket && activeTalentMarket.students.length}</span>
                <span className="text">{Data.talentMarkets.candidatesNumber}</span>
              </div>

              {/* <div>
                <span className="number">{company && company.data.talent_markets.length}</span>
                <span className="text">{Data.selectedCandidates.sideSmallText}</span>
              </div> */}
            </Section>
            {/* {company.data.talent_markets.map((market, i) => ( */}

            <CardsSelected students={activeTalentMarket.students && activeTalentMarket.students} selectedLang={selectedLang} />
            {/* )) */}
            {/* } */}
            <FooterContact fill="#000000" />
          </Wrapper>
        </SelectedCandidatesWrapper>
      }
      {company && !company.data.talent_markets.length && <ErrorPage />}
    </>
  );
}

export default SelectedCandidates;