import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Text20Normal } from '../FontStyle';
import usePopUp from '../Hooks/usePopUp';

const Mobile = styled.div`
   display:none;
   @media (max-width:768px){
    display:block;
    .read-or-hide{
        display:block;
        font-size:18px;
        font-weight:bold;
        color:#183FF3;
        text-decoration: underline;
        margin-top:16px;
    }
    .active{
        animation:fadeIn 0.5s;
        @keyframes fadeIn{
            0%{opacity:0;}  
            80%{opacity:0.5;}
            100%{opacity:1;}
        }
    }
 }
`
const Desktop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    @media (max-width:768px){
      display:none;
    }
`
const ReadMore = ({ text, textUnder }) => {
    const [popUp, handlePopUp] = usePopUp();
    return (
        <>
            <Desktop>
                <Text20Normal className="text arrowMobile">{text}</Text20Normal>
                <Text20Normal className="text" dangerouslySetInnerHTML={{ __html: textUnder.replace('{link}', `<a href="https://hiring.brainster.co" className="link">${Data.link}</a>`) }}></Text20Normal>
            </Desktop>
            <Mobile popUp={popUp}>
                <Text20Normal className="text arrowMobile"> {popUp ? text : text.slice(0, 160)}</Text20Normal>
                {popUp && <Text20Normal className={popUp ? "active text" : "text"} dangerouslySetInnerHTML={{ __html: textUnder.replace('{link}', `<a href="https://hiring.brainster.co" className="link">${Data.link}</a>`) }}></Text20Normal>}
                <span onClick={handlePopUp} className="read-or-hide">
                    {popUp ? Data.readLess : Data.readMore}
                </span>
            </Mobile>
        </>
    );
}
export default ReadMore